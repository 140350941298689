import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import accessOAPBlack from '../../assets/OAP black logo 80.svg';
import { useSearchParams } from 'react-router-dom';
import EnglishLogo from '../../assets/englishLogo';
import FrenchLogo from '../../assets/frenchLogo';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    marginRight: '10px',
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0',
      marginRight: ' 10px',
    },
  },
  imgContainer: {
    position: 'relative',
    top: '-3px',
    left: '-25px',
    width: '50%',
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
      {lang === 'fr' ? <FrenchLogo isWhite={false} /> : <EnglishLogo isWhite={false} />}
      <div>
        <Menu buttonClassName={classes.settingsButton} />
        <EndCallButton className={classes.endCallButton} />
      </div>
    </Grid>
  );
}
