import { ILanguageKeys } from './ILanguageKeys';

export const englishMessages: ILanguageKeys = {
  your_name: 'Your Name',
  host_key: 'Host key',
  no_audio: 'No audio',
  no_video: 'No video',
  join_as_host: 'Join as host',
  join: 'Join',
  //device selection
  join_now: 'Join',
  ask_join: 'Ask to join',
  cancel: 'Cancel',
  audioOff: 'Unmute',
  audioOn: 'Mute',
  videoOff: 'Start video',
  videoOn: 'Stop video',
  chat: 'Chat',
  settings: 'Settings',
  disconect: 'Disconnect',
  more: 'More',
  start_recording: 'Start Recording',
  stop_recording: 'Stop Recording',
  backgrounds: 'Backgrounds ',
  flip_camera: 'Flip Camera',
  room_monitor: 'Room Monitor',
  about: 'About',
  shareScreen: 'Share screen',
  you: ' (You)',

  welcome: 'Welcome',

  consent:
    'I consent to having this appointment recorded. I understand I can withdraw this consent any time by informing my Care Coordinator',

  contatc_us_1: 'You can access the ',
  contatc_us_2: ', or contact us at 1-833-425-2445.',
  family_portal_link: 'Family Portal',

  trubemessage_1: 'If you are having trouble joining the meeting,',
  trubemessage_2: 'click here',
  trubemessage_3: 'to get help.',

  loading: 'AccessOAP secure virtual meeting conference system',

  adjust_mesage: 'please adjust your camera and microphone and join the meeting when you are ready',

  AV_settings: 'Audio and Video Settings',
  conection_settings: 'Conection Settings',

  waiting_host: ' You have now joined the meeting, please wait for the host to admit you.',

  waiting_host_2:
    ' If it is past the start time for the meeting and the host has not yet joined, please confirm the appointment start time in your Family Portal or call 1-833-425-2445 to connect with a care coordinator for assistance.',

  error_title: 'We are sorry...',
  error_code: 'Error code: ',

  palcehoderChat: 'Write a message...',

  settings_title: 'Audio and Video Settings',
  settings_videoLabel: 'Video',
  settings_audioLabel: 'Audio',
  settings_completeBtn: 'Done',
  error_disconnected_title: 'Error',
  error_disconnected_message: 'An error occurred',
  error_disconnected_dismissButton: 'OK',
  //adimit prompt
  title_prompt: 'The following people are waiting to be approved into the meeting.',
  aceppt_prompt: 'Accept',
  reject_prompt: 'Reject',

  //Recording
  proceed_text: 'Are you sure you want to proceed? The ',
  proceed_text2: 'participant has not provided consent ',
  proceed_text3: 'for recording.',
  proceed_button_confirm: 'Proceed',
  proceed_button_denied: 'Cancel',

  //Errors
  booking_out_of_range_1: 'The room you are trying to enter is not currently available.',
  booking_out_of_range_2: 'Please check to ensure you have the correct date and time for your appointment.',
  invalid_host_key_1: 'The provided host key is not valid.',
  invalid_host_key_2: 'Please try again and ensure that you typed it correctly.',
};
