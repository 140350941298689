import React from 'react';

export default function ChatIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.875 1.5625C22.2894 1.5625 22.6868 1.72712 22.9799 2.02015C23.2729 2.31317 23.4375 2.7106 23.4375 3.125V15.625C23.4375 16.0394 23.2729 16.4368 22.9799 16.7299C22.6868 17.0229 22.2894 17.1875 21.875 17.1875H6.89687C6.06814 17.1877 5.27341 17.517 4.6875 18.1031L1.5625 21.2281V3.125C1.5625 2.7106 1.72712 2.31317 2.02015 2.02015C2.31317 1.72712 2.7106 1.5625 3.125 1.5625H21.875ZM3.125 0C2.2962 0 1.50134 0.32924 0.915291 0.915291C0.32924 1.50134 0 2.2962 0 3.125L0 23.1141C3.28357e-05 23.2687 0.0459259 23.4198 0.131871 23.5482C0.217816 23.6767 0.339948 23.7768 0.482811 23.8359C0.625674 23.8949 0.782845 23.9103 0.934431 23.88C1.08602 23.8497 1.2252 23.7751 1.33437 23.6656L5.79219 19.2078C6.08514 18.9148 6.48251 18.7501 6.89687 18.75H21.875C22.7038 18.75 23.4987 18.4208 24.0847 17.8347C24.6708 17.2487 25 16.4538 25 15.625V3.125C25 2.2962 24.6708 1.50134 24.0847 0.915291C23.4987 0.32924 22.7038 0 21.875 0L3.125 0Z"
        fill="black"
      />
    </svg>
  );
}
