import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../assets/CamOff.svg';
import VideoOnIcon from '../../../assets/CamOn.svg';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { makeStyles, Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button
      className={props.className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
      style={{ padding: '5px', paddingLeft: '15px' }}
      startIcon={
        isVideoEnabled ? (
          <img style={{ position: 'relative', top: '1px' }} src={VideoOnIcon} alt="Video On" />
        ) : (
          <img style={{ position: 'relative', top: '1px' }} src={VideoOffIcon} alt="Video On" />
        )
      }
    >
      {/* {!hasVideoInputDevices ? (
        <FormattedMessage id="no_video"></FormattedMessage>
      ) : isVideoEnabled ? (
        <FormattedMessage id="videoOn"></FormattedMessage>
      ) : (
        <FormattedMessage id="videoOff"></FormattedMessage>
      )} */}
    </Button>
  );
}
