import { ILanguageKeys } from './ILanguageKeys';

export const frenchMessages: ILanguageKeys = {
  //room name
  your_name: 'Votre nom',
  host_key: 'Mot de passe',
  join_as_host: 'Rejoindre en tant qu’organisateur',
  join: 'Rejoindre',
  you: ' (Vous)',
  //device selection
  join_now: 'Rejoindre',
  ask_join: 'Rejoindre',
  cancel: 'Annuler',

  //audio
  no_audio: 'Pas de son',
  audioOff: 'En sourdine',
  audioOn: 'Mettre en sourdine',
  //video
  no_video: 'Pas de vidéo',
  videoOff: ' Lancer la vidéo',
  videoOn: 'Arrêter Video',

  chat: 'Converser',
  disconect: 'Fin',

  settings: 'Réglages',

  more: 'Paramètres',

  shareScreen: "Partager l'écran",
  start_recording: "Démarrer d'enregistrer",
  stop_recording: "Arreter d'enregistrer",
  backgrounds: "Fond d'écran",
  flip_camera: 'Retourner la caméra',
  room_monitor: 'Statistiques',
  about: 'Information',

  welcome: 'Bienvenu',

  consent:
    "J'accepte l'enregistrement de ce rencontre. Je comprends que je peux retirer mon consentement en tout temps en communiquant avec mon coordinateur de soins.",

  trubemessage_1: 'Si vous rencontrez des difficultés pour rejoindre la réunion,',
  trubemessage_2: 'cliquez ici',
  trubemessage_3: "pour obtenir de l'aide",

  loading: 'Système de rencontres virtuelles sécurisé d’AccèsPOSA',

  adjust_mesage:
    'veuillez configurer votre caméra et votre microphone, puis vous joindre à la rencontre lorsque vous êtes prêt.',

  AV_settings: 'Paramètres audio et vidéo',
  conection_settings: 'Paramètres de connexion',
  waiting_host: ' Vous avez maintenant rejoint la réunion, veuillez attendre que l´hôte vous admette.',
  waiting_host_2:
    ' Si l´heure de début de la réunion est dépassée et que l´hôte ne s´est pas encore joint, veuillez confirmer l´heure de début de la réunion dans votre portail familial ou appeler le 1-833-425-2445 pour contacter un coordinateur de soins pour obtenir de l´aide.',

  error_title: 'Nous sommes désolés...',
  error_code: "Code d'erreur: ",

  contatc_us_1: 'Vous pouvez accéder au ',
  contatc_us_2: ', ou contactez-nous au 1-833-425-2445',
  family_portal_link: 'Portail Famille',

  palcehoderChat: 'Écrire un message...',

  settings_title: 'Parametres audio et video',
  settings_videoLabel: 'Video',
  settings_audioLabel: 'Audio',
  settings_completeBtn: 'Fini',
  error_disconnected_title: 'Rendez-vous virtuel termine',
  error_disconnected_message: 'An error occurred',
  error_disconnected_dismissButton: 'OK',
  //adimit prompot
  title_prompt: 'Les personnes suivantes attendent d’être approuvées pour la réunion.',
  aceppt_prompt: 'accepter',
  reject_prompt: 'rejeter',

  //Recording
  proceed_text: 'Êtes-vous sur de vouloir continuer? Le',
  proceed_text2: " participant n'a pas donné son accord",
  proceed_text3: " pour l'enregistrement.",
  proceed_button_confirm: 'Continuer',
  proceed_button_denied: 'Annuler',

  //Errors
  booking_out_of_range_1: 'La salle que vous essayez de rejoindre n’est pas disponible actuellement.',
  booking_out_of_range_2:
    'Veuillez vous assurer qu’il s’agit bel et bien de la date et de l’heure de votre rendez-vous.',
  invalid_host_key_1: "Le mot de passe pour l'organizateur est incorrect.",
  invalid_host_key_2: "Veuillez réessayer et assurez-vous de l'avoir saisi correctement.",
};
