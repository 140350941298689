import React, { useEffect, useRef, useState } from 'react';
import Snackbar from '../Snackbar/Snackbar';
import useIsRecording from '../../hooks/useIsRecording/useIsRecording';
import { useAppState, UserRole } from '../../state';
import { useSearchParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useSyncState } from '../../services/SyncProvider';
import { IParticipantApproval } from '../../types';

enum Snackbars {
  none,
  recordingStarted,
  recordingInProgress,
  recordingFinished,
}

export default function RecordingNotifications() {
  const [activeSnackbar, setActiveSnackbar] = useState(Snackbars.none);
  const { room } = useVideoContext();
  const [approval] = useSyncState<IParticipantApproval>(room?.name || '');
  const [allConsent, setAllConsent] = useState(true);
  const prevIsRecording = useRef<boolean | null>(null);
  const isRecording = useIsRecording();
  const { authInfo } = useAppState();

  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  useEffect(() => {
    // Show "Recording in progress" snackbar when a user joins a room that is recording
    if (isRecording && prevIsRecording.current === null) {
      setActiveSnackbar(Snackbars.recordingInProgress);
    }
  }, [isRecording]);

  useEffect(() => {
    // Show "Recording started" snackbar when recording has started.
    const _approvals = [...(approval?.participants || [])];
    _approvals.forEach(element => {
      if (element.consent === false) {
        setAllConsent(false);
      }
    });

    if (isRecording && prevIsRecording.current === false && !allConsent) {
      setActiveSnackbar(Snackbars.recordingStarted);
    }
  }, [isRecording]);

  useEffect(() => {
    // Show "Recording finished" snackbar when recording has stopped.
    if (!isRecording && prevIsRecording.current === true) {
      setActiveSnackbar(Snackbars.recordingFinished);
    }
  }, [isRecording]);

  useEffect(() => {
    prevIsRecording.current = isRecording;
  }, [isRecording]);

  return (
    <>
      {authInfo?.tokenDecoded.grants.flexUser.roles.includes(UserRole.Host) && (
        <>
          <Snackbar
            open={activeSnackbar === Snackbars.recordingStarted}
            handleClose={() => setActiveSnackbar(Snackbars.none)}
            variant="info"
            headline={lang === 'fr' ? "L'enregistrement a commencé. " : 'Recording has started.'}
            message={
              lang === 'fr'
                ? "À des fins de conformité, veuillez obtenir le consentement verbal de toutes les personnes participant à l'appel vidéo avant de continuer."
                : 'For compliance purposes, please confirm consent verbally with all parties on the video call before continuing.'
            }
          />
          <Snackbar
            open={activeSnackbar === Snackbars.recordingInProgress}
            handleClose={() => setActiveSnackbar(Snackbars.none)}
            variant="info"
            headline="Recording is in progress."
            message=""
          />
          <Snackbar
            open={activeSnackbar === Snackbars.recordingFinished}
            headline={lang === 'fr' ? "L'enregistrement est terminé." : 'Recording complete.'}
            message={
              lang === 'fr'
                ? 'Les enregistrements seront disponibles une fois que la salle sera fermée.'
                : 'Recordings will be available once this room has ended.'
            }
            variant="info"
            handleClose={() => setActiveSnackbar(Snackbars.none)}
          />
        </>
      )}
    </>
  );
}
