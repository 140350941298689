import React, { createContext, useContext } from 'react';
import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { englishMessages } from './lang/English';
import { frenchMessages } from './lang/French';
import { ILanguageKeys } from './lang/ILanguageKeys';

export enum Language {
  English = 'en',
  French = 'fr',
}

export const messages: Record<Language, ILanguageKeys> = {
  [Language.English]: englishMessages,
  [Language.French]: frenchMessages,
};

export type LanguageContextType = {
  currentLanguage: Language;
};

export const LanguageContext = createContext<LanguageContextType>({
  currentLanguage: Language.English,
});

export default function LanguageProvider(props: React.PropsWithChildren<{}>) {
  const [language, setLanguage] = useState<Language>(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(urlSearchParams.entries());
    const langParam: string = searchParams.lang ?? '';

    let result: Language = Language.English;
    for (let [key, value] of Object.entries(Language)) {
      if (
        langParam.localeCompare(key, undefined, { sensitivity: 'accent' }) === 0 ||
        langParam.localeCompare(value, undefined, { sensitivity: 'accent' }) === 0
      ) {
        result = value;
        break;
      }
    }

    return result;
  });

  return (
    <LanguageContext.Provider value={{ currentLanguage: language }}>
      <IntlProvider messages={messages[language]} locale={language} defaultLocale={Language.English}>
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

export function useLanguageState() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguageState must be used within the LanguageProvider');
  }
  return context;
}
