import React from 'react';

interface FrcLogoProps {
  isWhite: boolean;
}

export default function FrenchLogo({ isWhite }: FrcLogoProps) {
  return (
    <img
      className="absolute bottom-[10px] right-[20px]"
      src={
        isWhite
          ? 'https://hc365oapcalendar.s3.amazonaws.com/frcWhiteLogo.svg'
          : 'https://hc365oapcalendar.s3.amazonaws.com/frcLogo.svg'
      }
      width="180px"
    />
  );
}
