import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useSearchParams } from 'react-router-dom';
import { Disconnect } from '../../../services/disconnect';
import { useSyncState } from '../../../services/SyncProvider';
import { useAppState, UserRole } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      fontSize: '16px',
      fontFamily: 'Nunito',
      fontWeight: 600,
      borderRadius: '10px',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get('room') || '';
  const { authInfo } = useAppState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, deleteDoc] = useSyncState(room?.name || '');

  const endCall = () => {
    let isHost = authInfo?.tokenDecoded.grants.flexUser.roles.includes(UserRole.Host);
    console.log('Room id: ', roomId);
    if (isHost) {
      Disconnect(roomId);
      deleteDoc();
    } else {
      room!.disconnect();
    }
    return;
  };

  return (
    <Button
      onClick={() => {
        endCall();
      }}
      className={clsx(classes.button, props.className)}
      data-cy-disconnect
    >
      Disconnect
    </Button>
  );
}
