import { LocalVideoTrack, RemoteVideoTrack, TwilioError } from 'twilio-video';
import { UserRole } from './state';

declare module 'twilio-video' {
  // These help to create union types between Local and Remote VideoTracks
  interface LocalVideoTrack {
    isSwitchedOff: undefined;
    setPriority: undefined;
  }
}

declare global {
  interface Window {
    visualViewport?: {
      scale: number;
    };
  }

  interface MediaDevices {
    getDisplayMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
  }

  interface HTMLMediaElement {
    setSinkId?(sinkId: string): Promise<undefined>;
  }

  // Helps create a union type with TwilioError
  interface Error {
    code: undefined;
  }
}

export type Callback = (...args: any[]) => void;

export type ErrorCallback = (error: TwilioError | Error) => void;

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export type RoomType = 'group' | 'group-small' | 'peer-to-peer' | 'go';

export type AllowRecordingType = 'init' | 'allow' | 'deny' | 'notSet';

export type RecordingRule = {
  type: 'include' | 'exclude';
  all?: boolean;
  kind?: 'audio' | 'video';
  publisher?: string;
};

export type RecordingRules = RecordingRule[];

export interface ParticipantIdentityDecoded {
  name: string;
  role: UserRole;
}

export enum ParticipantApproval {
  APPROVED = 'approved',
  PENDING = 'pending',
  DENIED = 'denied',
}

export interface IParticipantApproval {
  hosts: Array<{
    name: string;
  }>;
  participants: Array<{
    name: string;
    approved: ParticipantApproval;
    consent: boolean;
  }>;
}
