import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import enhanceMessage from './enhanceMessage';
import { TwilioError } from 'twilio-video';
import Background from '../../assets/errorBg.svg';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  content1: {
    color: '#2CB890',
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '26px',
    marginBottom: '5px',
    marginTop: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },

  btn: {
    border: 'none',
    borderRadius: '28px',
    color: '#fff',
    fontFamily: 'Nunito',
    fontWeight: 600,
    fontSize: '20px',
    marginRight: '20px',
    background: '#2CB890',
    width: '100px',
    height: '40px',
    boxShadow: ' 0px 4px 8px 2px rgba(0, 0, 0, 0.25)',
    marginBottom: '25px',
  },

  title: {
    display: 'flex',
    fontFamily: 'Nunito',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-65px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  errorCode: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontFamily: 'Nunito',
    fontWeight: 300,
  },
  contactUs: {
    display: 'flex',
    fontSize: '10px',
    fontFamily: 'Nunito',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ContactContainer: {
    color: '#000',
    fontFamily: 'Nunito',
    fontWeight: 500,
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  dialogContent: {
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      Background: 'yellow',
      width: '100%',
    },
  },
  privacyPolicy: {
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#000',
    marginLeft: '0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  BookingOutOfRange: {
    color: '#000',
    fontWeight: 500,
    fontFamily: 'Nunito',
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | Error | null;
}

export default function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);
  const classes = useStyles();
  // const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    enhancedMessage !== 'Room completed' && (
      <>
        <Dialog
          open={error !== null}
          onClose={() => dismissError()}
          fullWidth={true}
          maxWidth="md"
          PaperProps={{
            style: {
              borderRadius: '25px',
              border: 'none',
              color: '#000',
              fontFamily: 'nunito',
              fontSize: '24px',
              fontWeight: 700,
              width: '600px',
              height: '350px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '15px',
            },
          }}
        >
          <DialogTitle>
            <div className={classes.title}>
              <p className={classes.content1}>
                <FormattedMessage id="error_title"></FormattedMessage>
              </p>
            </div>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <div className={classes.contactUs}>
              <DialogContentText>
                {enhancedMessage === 'Booking room is invalid or out of range.' && (
                  <>
                    <div className={classes.BookingOutOfRange}>
                      <span>
                        <FormattedMessage id="booking_out_of_range_1"></FormattedMessage>
                      </span>
                      <span className={classes.BookingOutOfRange}>
                        <FormattedMessage id="booking_out_of_range_2"></FormattedMessage>
                      </span>
                    </div>
                    <br></br>
                  </>
                )}

                {enhancedMessage === 'Invalid host key' && (
                  <>
                    <div className={classes.BookingOutOfRange}>
                      <span>
                        <FormattedMessage id="invalid_host_key_1"></FormattedMessage>
                      </span>
                      <span className={classes.BookingOutOfRange}>
                        <FormattedMessage id="invalid_host_key_2"></FormattedMessage>
                      </span>
                    </div>
                    <br></br>
                  </>
                )}

                {enhancedMessage !== 'Invalid host key' && (
                  <span className={classes.ContactContainer}>
                    <FormattedMessage id="contatc_us_1"></FormattedMessage>
                    <a className={classes.privacyPolicy} href="https://accessoap.ca/" target="_blank">
                      <FormattedMessage id="family_portal_link"></FormattedMessage>
                    </a>
                    <FormattedMessage id="contatc_us_2"></FormattedMessage>
                  </span>
                )}
              </DialogContentText>
            </div>

            <DialogActions>
              <button
                className={classes.btn}
                onClick={() => {
                  dismissError();
                }}
                color="primary"
                autoFocus
              >
                OK
              </button>
            </DialogActions>

            <div className={classes.errorCode}>
              <FormattedMessage id="error_code"></FormattedMessage>
              {enhancedMessage}
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
}
