import React from 'react';

interface EngLogoProps {
  isWhite: boolean;
}

export default function EnglishLogo({ isWhite }: EngLogoProps) {
  return (
    <img
      className="absolute bottom-[10px] right-[20px]"
      src={
        isWhite
          ? 'https://hc365oapcalendar.s3.amazonaws.com/engWhiteLogo.svg'
          : 'https://hc365oapcalendar.s3.us-east-1.amazonaws.com/OAP%2Bblack%2Blogo%2B80.svg'
      }
      width="180px"
    />
  );
}
