import React from 'react';
import Menu from './Menu/Menu';
import Button from '@material-ui/core/Button';
import accessOAPBlack from '../../assets/OAP black logo 80.svg';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Hidden, useMediaQuery } from '@material-ui/core';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import EnglishLogo from '../../assets/englishLogo';
import FrenchLogo from '../../assets/frenchLogo';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    menuContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '120px',
    },
    deviceAudioButton: {
      border: 'none',
      width: '80px',
      fontSize: '16px',
      fontFamily: 'Nunito',
      fontWeight: 600,

      paddingLeft: '15px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        paddingLeft: '5px',
      },
    },
    deviceVideoButton: {
      border: 'none',
      width: '180px',
      fontSize: '16px',
      fontFamily: 'Nunito',
      fontWeight: 600,

      paddingLeft: '20px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    deviceChatButton: {
      border: 'none',
      width: '180px',
      fontSize: '16px',
      fontFamily: 'Nunito',
      fontWeight: 600,
    },
    imgContainer: {
      position: 'relative',
      top: '-3px',
      left: '-25px',
    },
    endButtons: {
      position: 'relative',
      top: '-3px',
    },
    diconectButton: {
      position: 'relative',
      top: '-4px',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          You are sharing your screen
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              {lang === 'fr' ? <FrenchLogo isWhite={false} /> : <EnglishLogo isWhite={false} />}
            </Grid>
          </Hidden>
          <Grid item>
            <div className={classes.menuContainer}>
              <ToggleAudioButton className={classes.deviceAudioButton} />
              <ToggleVideoButton className={classes.deviceVideoButton} />
              <div className={classes.deviceChatButton}>
                {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
              </div>
            </div>
          </Grid>
          <Hidden smDown>
            <Grid className={classes.endButtons} style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <Hidden smDown>
                  <Menu />
                </Hidden>
                <div className={classes.diconectButton}>
                  <EndCallButton />
                </div>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
