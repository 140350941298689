import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';
import { useAppState, UserRole } from '../../state';
import useIsRecording from '../useIsRecording/useIsRecording';
import { IParticipantApproval } from '../../types';
import { useSyncState } from '../../services/SyncProvider';

export default function useParticipants() {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));
  const [approval, setApprovals] = useSyncState<IParticipantApproval>(room?.name || ''); //loop

  const isRecording = useIsRecording();
  const { updateRecordingRules, authInfo, allowRecording, setAllowRecording } = useAppState();

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    if (room) {
      const participantConnected = (participant: RemoteParticipant) =>
        setParticipants(prevParticipants => [...prevParticipants, participant]);
      const participantDisconnected = (participant: RemoteParticipant) =>
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  useEffect(() => {
    let allConsent = true;
    const _approvals = [...(approval?.participants || [])];
    allConsent = _approvals.length > 0;

    _approvals.forEach(element => {
      if (element.consent === false) {
        allConsent = false;
      }
    });

    if (!isRecording && authInfo?.tokenDecoded.grants.flexUser.roles.includes(UserRole.Host)) {
      if ((allConsent && participants.length > 0 && allowRecording === 'init') || allowRecording === 'allow') {
        updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
        if (allowRecording !== 'init') setAllowRecording('notSet');
      }
    }

    if (isRecording && authInfo?.tokenDecoded.grants.flexUser.roles.includes(UserRole.Host)) {
      if ((!allConsent && allowRecording === 'init') || allowRecording === 'deny') {
        updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
        if (allowRecording !== 'init') setAllowRecording('notSet');
      }
    }
  }, [isRecording, authInfo, allowRecording, participants]); // eslint-disable-line

  return participants;
}
