import React, { useState, useEffect, useCallback, createContext, useContext, ReactNode } from 'react';
import { Client, SyncDocument } from 'twilio-sync';

const SyncContext = createContext<Client | undefined>(undefined);

interface ISyncProvider {
  tokenFn: () => Promise<string>;
  children: ReactNode;
}

export default function SyncProvider({ tokenFn, children }: ISyncProvider) {
  const [syncClient, setSyncClient] = useState<Client>();

  useEffect(() => {
    (async () => {
      if (!syncClient) {
        const token = await tokenFn();
        const client = new Client(token);
        client.on('tokenAboutToExpire', async () => {
          const _token = await tokenFn();
          await client.updateToken(_token);
        });
        setSyncClient(client);
      }
    })();

    return () => {
      if (syncClient) {
        syncClient.shutdown();
        setSyncClient(undefined);
      }
    };
  }, [syncClient]);

  return <SyncContext.Provider value={syncClient}>{children}</SyncContext.Provider>;
}

interface ISyncStateData<TData> {
  state: TData;
}

type TSyncStateReturn<TData> = [TData | undefined, (value: TData) => void, () => void];

export function useSyncState<TData = unknown>(name: string, initialValue?: TData): TSyncStateReturn<TData> {
  const sync = useContext(SyncContext);
  const [doc, setDoc] = useState<SyncDocument>();
  const [data, setDataInternal] = useState<TData>();

  useEffect(() => {
    setDoc(undefined);
    setDataInternal(undefined);
  }, [sync]);

  useEffect(() => {
    (async () => {
      if (sync && !doc) {
        const newDoc = await sync.document(name);
        newDoc.setTtl(60 * 60 * 6); //6 hours
        if (Object.keys(newDoc.data).length === 0) {
          await newDoc.set({ state: initialValue });
        }

        setDoc(newDoc);
        if ((newDoc.data as ISyncStateData<TData>).state) {
          setDataInternal((newDoc.data as ISyncStateData<TData>).state);
        }
        newDoc.on('updated', args => setDataInternal(args.data.state));
      }
    })();
    return () => {
      doc && doc.close();
    };
  }, [sync, doc, name, initialValue]);

  const setData = useCallback(
    (value: TData) => {
      (async () => {
        if (typeof value === 'function') {
          await doc?.set({ state: value(data) });
        } else {
          await doc
            ?.set({ state: value })
            .then(res => console.log(res))
            .catch(err => console.log(err));
        }
      })();
    },
    [doc, data]
  );

  const deleteDoc = useCallback(() => {
    doc?.removeDocument();
  }, [doc]);

  return [data, setData, deleteDoc];
}
