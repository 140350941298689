import React from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import secondaryLgo from '../../assets/acc-AccessOAP_Secondary.svg';
import Background from '../../assets/Backgorund.svg';
import MobileBackground from '../../assets/MobileOAPBG.svg';
import { useParams, useSearchParams } from 'react-router-dom';
import EnglishLogo from '../../assets/englishLogo';
import FrenchLogo from '../../assets/frenchLogo';
import styles from './style.module.css';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },

    height: '100%',
    padding: 0,
  },

  troubleLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#fff',
    fontFamily: 'nunito',
    fontSize: '24px',

    marginTop: '60px',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
      letterSpacing: '0px',
    },
  },

  anchor: {
    color: '#fff',
    marginLeft: '5px',
    marginRight: '5px',
  },

  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '700px',
    height: 'auto', //Was 379px for some reason, but couldn't fit everything so changing to auto for now.
    borderRadius: '10px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',

    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#000',
    backgroundSize: 'cover',
    width: '296px',
    borderRadius: '36px 0 0 36px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '-100px',
    },
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get('lang');
  return (
    <div className={styles.main}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          {/* <div className={classes.swooshContainer}>
            <div>{lang === 'fr' ? <FrenchLogo isWhite={true} /> : <EnglishLogo isWhite={true} />}</div>
          </div> */}
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
