import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useAppState } from '../../../../../state';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Divider, Dialog, DialogActions, Button, Theme, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  buttonOk: {
    float: 'right',
    backgroundColor: '#54b531',
    '&:hover': {
      backgroundColor: '#2d601a',
    },
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
}));

export default function PhoneNumberSelectionDialog({
  open,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: (phoneNumber: string) => void;
}) {
  const { taskInfo } = useAppState();
  const [phoneNumber, setPhoneNumber] = useState<string>(() => {
    if (taskInfo?.patientPhone == null || taskInfo.patientPhone === '') return '';

    let result: string = taskInfo.patientPhone;
    if (result.startsWith('+')) result = result.substr(1);

    if (isNaN(+result)) return '';

    return result;
  });
  const classes = useStyles();

  const confirmAction = () => {
    onConfirm(`+${phoneNumber}`);
  };

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.paper }}>
      <DialogTitle>Call to</DialogTitle>
      <Divider />
      <DialogContent className={classes.container}>
        <PhoneInput
          value={phoneNumber}
          country="ca"
          onlyCountries={['ca']}
          onChange={setPhoneNumber}
          specialLabel=""
          inputStyle={{ width: '100%' }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" variant="contained" className={classes.button} onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" className={classes.buttonOk} onClick={confirmAction}>
          Call
        </Button>
      </DialogActions>
    </Dialog>
  );
}
