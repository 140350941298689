import { Button, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import bgAdmit from '../../assets/AdmitPrompt.svg';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useSyncState } from '../../services/SyncProvider';
import { useAppState, UserRole } from '../../state';
import { IParticipantApproval, ParticipantApproval } from '../../types';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import ChatWindow from '../ChatWindow/ChatWindow';
import MainParticipant from '../MainParticipant/MainParticipant';
import ParticipantList from '../ParticipantList/ParticipantList';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;
  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px` },
    admitPrompt: {
      height: '200px',
      width: '440px',
      // backgroundImage: `url(${bgAdmit})`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: 'cover',
      background: 'whitesmoke',
      color: 'black',
      border: 'none',
      borderRadius: '20px',
      padding: '30px',
    },
    acceptButton: {
      cursor: 'pointer',
      fontSize: '18px',
      fontFamily: 'Nunito',
      fontWeight: 400,
      padding: '10px',

      background: '#1566c0',
      color: '#fff',
      // marginLeft: '10px',
      border: 'none',
      borderRadius: '80px',

      //boxShadow: ' 0px 8px 13px 3px rgba(0, 0, 0, 0.25)',

      width: '100px',
      height: '40px',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      // float: 'left',

      transition: '0.2s',
      '&:hover': {
        backgroundColor: '#0E548E',
      },
    },

    rejectButton: {
      background: 'whitesmoke',
      border: 'none',
      color: 'gray',
      fontSize: '18px',
      fontFamily: 'Nunito',
      fontWeight: 500,
      marginLeft: '40px',
      marginRight: '25px',
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '50px',
    },
    text: {
      color: 'black',
      fontSize: '18px',
      fontFamily: 'Nunito',
      fontWeight: 500,
      paddingRight: '40px',
    },
  };
});

export default function Room() {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const { isBackgroundSelectionOpen, room } = useVideoContext();
  const { authInfo } = useAppState();
  const [approval, setApprovals] = useSyncState<IParticipantApproval>(room?.name || '');

  //ParticipantApproval.PENDING
  let pending = approval?.participants.some(p => p.approved === ParticipantApproval.PENDING);

  const handleApproval = (name: string, approved: ParticipantApproval, consent: boolean) => {
    const _approvals = [...(approval?.participants || [])];
    let _hosts = [...(approval?.hosts || [])];
    const updatedApprovals = _approvals.map(_approval => {
      if (_approval.name === name && _approval.approved === ParticipantApproval.PENDING) {
        return {
          name,
          approved,
          consent,
        };
      } else {
        return _approval;
      }
    });
    setApprovals({ hosts: [..._hosts], participants: updatedApprovals });
  };

  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]: isChatWindowOpen || isBackgroundSelectionOpen,
      })}
    >
      {authInfo?.tokenDecoded.grants.flexUser.roles.includes(UserRole.Host) && (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            className={classes.admitPrompt}
            open={pending}
            action={
              <IconButton size="small" aria-label="close" color="inherit">
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            <div>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                style={{ position: 'absolute', right: '20px' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <Typography
                className={classes.text}
                style={{ paddingRight: '0px', paddingBottom: '20px', textAlign: 'center', width: '320px' }}
              >
                <FormattedMessage id="title_prompt" />
              </Typography>
              {approval?.participants.map(
                p =>
                  p.approved === ParticipantApproval.PENDING && (
                    <div
                      style={{ display: 'flex', paddingBottom: '10px', width: '320px', alignItems: 'center' }}
                      key={p.name}
                    >
                      <Typography className={classes.text} style={{ width: '80px' }}>
                        <b>{p.name}</b>
                      </Typography>
                      <Button
                        className={classes.acceptButton}
                        onClick={() => handleApproval(p.name, ParticipantApproval.APPROVED, p.consent)}
                      >
                        <FormattedMessage id="aceppt_prompt" />
                      </Button>
                      <Button
                        className={classes.rejectButton}
                        onClick={() => handleApproval(p.name, ParticipantApproval.DENIED, p.consent)}
                      >
                        <FormattedMessage id="reject_prompt" />
                      </Button>
                    </div>
                  )
              )}
            </div>
          </Snackbar>
        </>
      )}
      <MainParticipant />
      <ParticipantList />
      <ChatWindow />
      <BackgroundSelectionDialog />
    </div>
  );
}
